<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.CUSTOMER_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.name_kana_like.label }}</label>
              <app-input :name="filters.name_kana_like.name" input-style="normal"
                         v-model="filters.name_kana_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.email_like.label }}</label>
              <app-input :name="filters.email_like.name" input-style="normal"
                         v-model="filters.email_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.phone_number_like.label }}</label>
              <app-input :name="filters.phone_number_like.name"
                         input-style="normal"
                         type="phone"
                         placeholder="00000000000"
                         v-model="filters.phone_number_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.name"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"

              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-1 col-lg-2 col-sm-2 title-radio col-form-label">{{ filters.status_enabled_equal.label }}</label>
          <div class="col-xl-5 col-lg-8 col-sm-8 gr-icheck">
            <app-radio
              class="app-inline"
              :name="filters.status_enabled_equal.name"
              v-model="filters.status_enabled_equal.value"
              val="all"
              label="全て"
              isMarginLeft="true"
            />
            <app-radio
              class="app-inline"
              :name="filters.status_enabled_equal.name"
              v-model="filters.status_enabled_equal.value"
              val="1"
              label="有効"
              isMarginLeft="true"
            />
            <app-radio
              class="app-inline"
              :name="filters.status_enabled_equal.name"
              v-model="filters.status_enabled_equal.value"
              val="0"
              label="無効"
              isMarginLeft="true"
            />
          </div>
        </div>
      </template>
      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>
      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>
      <template v-slot:body-cell-delete="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnDeleteClick(props.row)"
            class="btn btn-warning"
          >
            {{ $t("common.delete") }}
          </button>
        </td>
      </template>
      <template v-slot:body-cell-status_enabled="props">
        <td class="app-align-middle min-w-50">
          {{ getStatusText(props.row.status_enabled) }}
        </td>
      </template>
      <template v-slot:body-cell-line_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getLineID(props.row.line_id)" class="app-table-p app-cell-tooltip mb-0">
            {{ getLineID(props.row.line_id) }}
          </p>
        </td>
      </template>
<!--      <template v-slot:body-cell-apartment_id="props">-->
<!--        <td class="app-align-middle">-->
<!--          <a-->
<!--            :href="getApartmentEditUrl(props.row.apartment_id)"-->
<!--            target="_blank">-->
<!--            <p :data-original-title="getApartmentName(props.row.apartment_id)" class="app-table-p app-cell-tooltip mb-0">-->
<!--              {{ getApartmentName(props.row.apartment_id) }}-->
<!--            </p>-->
<!--          </a>-->
<!--        </td>-->
<!--      </template>-->

      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{getApartmentName(props.row.apartment_id)}}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-updated_at="props">
        <td class="app-align-middle text-center min-w-150">
          <p data-original-title="2021-12-20" class="app-table-p app-cell-tooltip mb-0">{{ getDateUpdatedAt(props.row.updated_at) }}</p>
        </td>
      </template>
    </app-basic-table>

    <app-modal-confirm
      ref="modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="handleConfirmDeleteClick"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
  </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ROLES, BASIC_TABLE} from "@constants";

export default {
  name: "ListAllCustomer",

  components: {
    "app-basic-table": AppBasicTable
  },

  data() {
    return {
      idCache: null,
      paramGetList: {},
      filters: {
        email_like: {
          name: "email",
          condition: "like",
          label: this.$t("common.email")
        },
        name_kana_like: {
          name: "search_kana_or_name",
          condition: "like_name",
          label: this.$t("common.search_customer_name")
        },
        status_enabled_equal: {
          name: "status_enabled",
          condition: "equal",
          label: this.$t("customers.status_enabled")
        },
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("customers.apartment")
        },
        phone_number_like: {
          name: "phone_number",
          condition: "like",
          label: this.$t("common.phone")
        },
      },
      columns: [
        {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
        {name: "status_enabled", label: this.$t("common.status"), sortable: true},
        {name: "line_id", label: this.$t("customers.line_id"), sortable: true},
        {name: "email", label: this.$t("common.email")},
        {name: "name_last", label: "姓", sortable: true},
        {name: "name_first", label: "名", sortable: true},
        {name: "apartment_id", label: this.$t("customers.apartment"), sortable: true},
        {name: "room_no", label: this.$t("customers.room"), textAlign: 'text-center'},
        {name: "phone_number", label: this.$t("common.phone"), textAlign: 'text-center'},
        {name: "created_at", label: this.$t("common.created at"), textAlign: 'text-center', sortable: true},
        {name: "updated_at", label: this.$t("common.updated at"), textAlign: 'text-center', sortable: true},
        {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
      ],
      meta: {
        apartments: [],
        blood_types: [],
        blood_rhs: [],
        gender_codes: [],
        setai_codes: [],
        status: [],
      }
    };
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER).then(res => {
      let apartments = _.cloneDeep(res.data.apartments);
      apartments.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        apartments: apartments,
        blood_types: res.data.blood_types,
        blood_rhs: res.data.blood_rhs,
        gender_codes: res.data.gender,
        setai_codes: res.data.setais,
        status: res.data.status,
      }
    })
  },

  methods: {
    handleBtnCreateClick() {
      this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_CREATE})
    },

    handleBtnUpdateClick(entry) {
      this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_EDIT,params:  {id: entry.id}})
    },

    handleBtnDeleteClick(entry) {
      this.idCache = entry.id;
      this.$refs.modal_delete.show();
    },
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
      window.open(routeApartment.href, '_blank');
    },
    async handleConfirmDeleteClick() {
      let endpoint = this.ENDPOINT.USERS_DELETE;
      let response = await this.$request.delete(endpoint(this.idCache));
      if (!response.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.basic_table.getEntries();
      } else {
        this.__noticeError(response.data?.error_msg);
      }
      this.$refs.modal_delete.hide();
    },

    getStatusText(status) {
      return this.meta.status[status]
    },

    getApartmentName(apartmentId) {
      let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
      if (apartment == undefined) {
        return '';
      }
      return apartment.name;
    },

    getApartmentEditUrl(apartmentId) {
      let props = this.$router.resolve({
        name: this.ROUTES.ADMIN.APARTMENT_EDIT,
        params: { id: apartmentId },
      });

      return props.href;
    },
    getLineID(lineId) {
      return lineId ?? '未登録';
    },
    getDateUpdatedAt(updatedAt) {
      return this.__formatDate(updatedAt);
    },
    onResetAllSearch() {
      // this.$router.push({name: this.$route.name, query: {'filters.status_enabled.equal': 'all', 'filters.apartment_id.equal': 'all'}})
      this.$router.push({name: this.$route.name,        query : {
          'filters.status_enabled.equal' : 'all',
          'filters.apartment_id.equal' : 'all',
        }})
    },
    onSearch() {
      // this.getTourSelectName();
    },
  },
};
</script>
